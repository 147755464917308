import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import get from 'lodash.get';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/woman-with-glasses-talking.jpg';
import CtaDeck from '../components/common/cta-deck';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { EMPLOYMENT_BASED_IMMIGRATION_TESTIMONIALS } from '../data/employment-based-immigration/testimonials';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';

import CategorySlider from '../components/blog/category-slider';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'Employment-Based Immigrant Visa & Green Card USA | Pandev Law',
  description:
    'Searching for a trusted and experienced Employment-Based Immigration lawyer? We can help you in all Employment-Based Visa and Green Card categories. Talk to us now!',
  keywords:
    'employment-based immigration,employment-based immigrant visa,employment-based immigrant green card,business immigration,business immigration law,corporate immigration,corporate immigration services',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Employment-Based Immigrant Visa & Green Card USA | Pandev Law",
    "description": "Searching for a trusted and experienced Employment-Based Immigration lawyer? We can help you in all Employment-Based Visa and Green Card categories. Talk to us now!",
    "url": "https://www.pandevlaw.com/employment-based-visa-immigration",
    "image": "https://pandevlaw.com/img/woman-with-glasses-talking.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/employment-based-visa-immigration/"
  },
  "headline": "Employment-Based Immigration",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

const title = 'Employment-Based Immigration';

function EmploymentBaseImmigration({ location, data }) {
  const category = get(data, 'category', null);

  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero title={title} img={heroImg} imgAlt={title}>
        <p className="text-lead mb-5 mb-md-6">
          Our employment-based immigration lawyers have extensive experience developing
          effective work visa USA and employment-based green card solutions for
          individuals and organizations of all sizes. Our attorneys use their substantial
          employment-based immigration experience to represent employers and employees
          from various types of organizations, including start ups, small businesses,
          multinational corporations, nonprofits, schools, hospitals, laboratories, and
          investment funds. We devise unique work visa USA sponsorship strategies for
          companies in a diverse range of industries, including technology, education,
          finance, hospitality, and healthcare, among others. Our attentive, personal
          approach to your case remains the same regardless of the size of your company or
          your industry. We will listen to your goals and help develop a customized
          employment-based immigration plan tailored to your and your company’s needs. Our
          personalised work visa USA services include:
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="Employment-Based Immigration Testimonials"
        testimonials={EMPLOYMENT_BASED_IMMIGRATION_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/women-talking.jpg"
                alt="Employment-Based Green Card"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">Employment-Based Green Card</h2>
              <p className="text-body mb-6">
                Employers are able to sponsor their foreign national employees for an
                employment-based green card. The employment-based green card grants the
                foreign national employee permanent residence in the United States. As a
                permanent resident, the employment-based green card holder may apply for
                US citizenship through naturalization after five years. This pathway to
                citizenship is one advantage of the employment-based green card over a
                temporary work visa USA. Employment-based green cards are split into five
                different categories. Our work visa USA lawyers have extensive experience
                with all five employment-based green card categories:
              </p>
              <div className="accordion" id="corporateLaw">
                <div className="mb-5">
                  <div id="corporateLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseOne"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseOne">
                      <strong>EB-1 Green Card</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="corporateLawHeadingOne"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      The employment-based first preference (EB-1) green card is reserved
                      for foreign nationals of extraordinary ability, outstanding
                      professors or researchers, or multinational managers or executives.
                      The EB-1 employment-based green card category has historically not
                      had long backlogs due to limited quotas. This makes the EB-1 green
                      card category highly popular with qualifying foreign nationals. Our
                      employment-based immigration attorneys have the necessary experience
                      and know-how with the employment-based green card process to guide
                      you through your EB-1 green card case in all three EB-1
                      subcategories:
                    </p>
                    <p>
                      <strong>Individuals of Extraordinary Ability (EB-1A)</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/what-is-an-eb1-extraordinary-ability-green-card/"
                        target="_blank"
                        rel="noreferrer noopener">
                        EB-1A employment-based green card
                      </a>{' '}
                      is reserved for foreign nationals who have risen to the very top of
                      their field of endeavor. To qualify for an EB-1A green card, you
                      must demonstrate extraordinary ability in the sciences, arts,
                      education, business, or athletics and must intend to continue to
                      work in the same field of endeavor. The EB-1A employment-based green
                      card category does not necessarily require an employer sponsor. Our
                      employment-based immigration lawyers have experience helping foreign
                      nationals self-petition for a green card in the EB-1A category.
                    </p>
                    <p>
                      <strong>Outstanding Professors or Researchers (EB-1B)</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The EB-1B outstanding professors or researchers employment-based
                      green card is reserved for foreign professors and researchers who
                      are internationally recognized as outstanding in their academic
                      field. Petitions for an EB-1B employment-based green card must be
                      filed by a US employer. Applicants for an EB-1B green card cannot
                      self-sponsor. Both the US employer and the foreign national
                      applicant must meet specific requirements in order for an EB-1B
                      petition to be successful. Our work visa USA team understands the
                      employment-based green card process and has the requisite experience
                      to advise both US employers and foreign professors or researchers on
                      the best EB-1B green card strategy for their case.
                    </p>
                    <p>
                      <strong>Multinational Managers and Executives (EB-1C)</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The EB-1C employment-based green card is reserved for multinational
                      managers and executives. To qualify for an EB-1C green card, the
                      foreign national applicant must have been employed abroad at a
                      qualifying affiliated entity and been transferred to the United
                      States to work in a managerial or executive capacity. Although the
                      EB-1C green card requires a US employer sponsor, it is also an
                      effective green card strategy for foreign entrepreneurs who have
                      started a US business on an L-1 visa. Our employment-based
                      immigration lawyers understand how to apply for work visa in USA and
                      have helped numerous foreign executives and managers through the
                      EB-1C green card process. We can help devise a custom-tailored
                      employment-based green card strategy for your case.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseTwo"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseTwo">
                      <strong>EB-2 Green Card</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingTwo"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      The EB-2 employment-based green card is reserved for applicants with
                      advanced degrees, exceptional ability, or those whose work is in the
                      national interest of the United States. The EB-2 employment-based
                      green card is a popular option among foreign nationals because the
                      green card backlogs in this category tend to be shorter than those
                      in the EB-3 category. Moreover, the national interest waiver option
                      allows foreign nationals to self-petition for an employment-based
                      green card without the need for a US employer sponsor. Our attorneys
                      understand work visa USA sponsorship and guide US employers and
                      their employees through the EB-2 green card process.
                    </p>
                    <p>
                      <strong>Advanced Degree</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      To qualify for an Advanced Degree EB-2 employment-based green card,
                      the position for which you are being sponsored must require an
                      advanced degree. Also, you must hold an advanced degree or,
                      alternatively, hold a bachelor’s degree and have five years of
                      progressive work experience in the field.{' '}
                    </p>
                    <p>
                      <strong>Exceptional Ability</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      To qualify for an Exceptional Ability EB-2 employment-based green
                      card, you must be able to show exceptional ability in the sciences,
                      arts, or business. Exceptional ability is defined as “a degree of
                      expertise significantly above that ordinarily encountered in the
                      sciences, arts, or business.”
                    </p>
                    <p>
                      <strong>National Interest Waiver</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The National Interest Waiver allows qualifying applicants to
                      self-petition for an EB-2 employment-based green card. To qualify
                      for a National Interest Waiver, your proposed endeavor in the United
                      States must be of both substantial merit and of national importance.
                      You must demonstrate that you are well-positioned to advance the
                      proposed endeavor and that it would be beneficial to the United
                      States to waive the labor certification and job offer requirements
                      of EB-2 green cards.
                      <br />
                      <br />
                      Generally, a National Interest Waiver is granted to individuals who
                      can demonstrate exceptional ability in the sciences, arts, or
                      business. Our work visa USA team has helped professionals in various
                      fields obtain a National Interest Waiver EB-2 employment-based green
                      card.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseThree"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseThree">
                      <strong>EB-3 Green Card</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseThree"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingThree"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      The EB-3 employment-based third preference green card category is
                      reserved for skilled and unskilled workers as well as professionals
                      who do not meet the requirements of the EB-2 category. Skilled
                      workers are those whose position requires at least two years of work
                      experience or job related training. Professionals are those who hold
                      a bachelor’s degree and whose position requires a bachelor’s degree.
                      The unskilled workers, or other workers, category includes all
                      workers who cannot be classified into any of the other EB-3
                      categories. All EB-3 green card petitions must first go through the
                      PERM labor certification process. Our employment-based immigration
                      lawyers understand the employment-based green card process and have
                      successfully represented numerous US employers and foreign national
                      employees in their EB-3 green card petition.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingFour">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseFour"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseFour">
                      <strong>PERM Labor Certification Process</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseFour"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingFour"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      All employment-based green card petitions in the EB-2 and EB-3
                      category, except those that qualify for a National Interest Waiver,
                      must first go through the PERM labor certification process. The{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/how-to-get-an-employer-sponsored-green-card/"
                        target="_blank"
                        rel="noreferrer noopener">
                        PERM labor certification
                      </a>{' '}
                      is the first step in obtaining an employment-based green card in the
                      EB-2 and EB-3 categories. Before a US employer can file a petition
                      for an employment-based green card for a foreign national employee,
                      the employer must first be granted a labor certification from the US
                      Department of Labor. The employer must undergo a strict recruitment
                      process and must demonstrate to the Department of Labor that it
                      could not find able, willing, qualified, and available US workers
                      for the position offered to the foreign national. Only after the
                      labor certification is obtained from the Department of Labor, can
                      the employer file an employment-based green card petition with
                      USCIS. Our lawyers understand the work visa USA sponsorship process
                      and have the necessary experience and know-how to help your
                      organization through the PERM labor certification process, including
                      the PERM Audit process, for both EB-2 and EB-3 green card cases.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingSix">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseSix"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseSix">
                      <strong>EB-4 Green Card</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseSix"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingSix"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      The EB-4 employment-based fourth preference green card is reserved
                      for special immigrants. The EB-4 employment-based green card
                      category includes a broad range of candidates, including religious
                      workers and retired officers or employees of international
                      organizations in G-4 status or NATO-6 status. Although many EB-4
                      green card petitions are filed by employers, the EB-4 green card
                      category allows foreign nationals to self-petition. Our
                      employment-based immigration lawyers understand the EB-4
                      employment-based green card process and can guide you through your
                      EB-4 green card petition.{' '}
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingSeven">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseSeven"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseSeven">
                      <strong>EB-5 Green Card</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseSeven"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingSeven"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      The EB-5 employment-based fifth preference green card is reserved
                      for{' '}
                      <Link to="/investor-visa-usa-immigration/">foreign investors</Link>{' '}
                      who make a significant investment in a US commercial enterprise. The{' '}
                      <Link to="/eb-5-visa/"> EB-5 green card </Link> is an excellent way
                      for foreign nationals with significant financial resources to obtain
                      a green card through investment. If you do not have a U.S. employer
                      who can sponsor you for an employment-based green card or you are
                      unable to self-petition for a green card, the EB-5 green card may be
                      the best option for you to obtain permanent residence in the United
                      States. The EB-5 green card is also a good immigration solution for
                      foreign entrepreneurs whose startup business requires substantial
                      capital investment and over 10 full-time US workers. Our
                      employment-based immigration lawyers have the experience and
                      know-how to help you obtain an EB-5 employment-based green card.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/man-with-watch.jpg"
                alt="Work Visa USA"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Work Visa USA</h2>
              <p className="text-body mb-6">
                The United States provides several work visa USA options for organizations
                who wish to employ foreign national workers. Our employment-based
                immigration team can help your organization meet its growth and talent
                goals by sponsoring a temporary work visa USA for your foreign national
                employees, including local hires, intracompany transfers, temporary or
                seasonal workers, or foreign trainees, among others. Our attorneys
                understand how to get a working visa for USA and can guide you through the
                work visa USA sponsorship process. A work visa USA is different from an
                employment-based green card. A work visa has a temporary duration and
                limits the employment activities in which the visa holder may engage. In
                contrast, the employment-based green card provides the holder with
                permanent residence with unlimited lawful employment opportunities. Our
                attorneys have a thorough understanding of the work visa USA process and
                have helped numerous organizations sponsor their foreign national
                employees for all available work visas in the United States:
              </p>
              <div className="accordion" id="workVisaUSA">
                <div className="mb-5">
                  <div id="workVisaUSAHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#workVisaUSACollapseOne"
                      aria-expanded="true"
                      aria-controls="workVisaUSACollapseOne">
                      <strong>Professional Work Visa USA</strong>
                    </h2>
                  </div>
                  <div
                    id="workVisaUSACollapseOne"
                    className="collapse show"
                    aria-labelledby="workVisaUSAHeadingOne"
                    data-parent="#workVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      Our employment-based immigration lawyers have guided organizations
                      in various industries and of various sizes through the professional
                      work visa USA process. Our immigration attorneys understand work
                      visa USA sponsorship and can devise a custom-tailored immigration
                      strategy for your organization. Our lawyers have extensive
                      experience with all professional work visa categories:
                    </p>
                    <p>
                      <strong>H-1B Specialty Occupation Work Visa USA</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/what-is-an-h-1b-visa/"
                        target="_blank"
                        rel="noreferrer noopener">
                        H-1B visa
                      </a>{' '}
                      is reserved for foreign nationals working in a specialty occupation
                      requiring at least a bachelor’s degree, such as engineers, teachers,
                      scientists, financial analysts, among others. The H-1B is a
                      temporary work visa USA with an initial period of stay up to three
                      years which can be extended for an additional three year period. A
                      foreign national in H-1B status may only work for the H-1B sponsor
                      employer. An H-1B worker’s immediate family members, spouses and
                      unmarried children under the age of 21, may qualify for an H-4
                      dependent visa which will allow them to accompany the principal H-1B
                      worker to the United States. Because the H-1B is a dual intent visa,
                      it is a great work visa USA option for employer’s wishing to start
                      the employment-based green card process for a foreign worker.
                      <br />
                      <br />
                      Each year the government issues a maximum of 85,000 H-1B visas.
                      Because the H-1B is often the first choice visa for those wondering
                      how to get a working visa for USA, there are significantly more
                      applications than H-1B visas available each year. The annual H-1B
                      lottery determines which applications are selected by USCIS for
                      processing. Our employment-based immigration attorneys have helped
                      numerous organizations through the H-1B petition and H-1B lottery
                      registration process. A niche practice of our law firm is helping
                      organizations establish cap-exempt status with USCIS which allows
                      them to file H-1B petitions that are not counted against the annual
                      limit and can bypass the H-1B lottery. In addition to our extensive
                      professional experience with the H-1B temporary work visa USA
                      process, we also understand the personal benefits of the H-1B visa
                      since our principal attorney, Adrian Pandev, held an H-4 dependent
                      visa for many years.
                    </p>
                    <p>
                      <strong>
                        H-1B1 Chilean & Singaporean Specialty Occupation Work Visa USA
                      </strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The H-1B1 work visa USA originates from the Free Trade Agreements
                      between the United States and Chile and Singapore. The H-1B1 work
                      visa USA program allows up to 1,400 Chilean and 5,400 Singaporean
                      specialty workers to obtain a US work visa each year. Similarly to
                      the H-1B, the H-1B1 visa is reversed for those working in a
                      specialty occupation requiring at least a bachelor’s degree. Because
                      only a limited number of H-1B1 visa applications are filed each
                      year, the annual limit on H-1B1 visa issuance has never been
                      reached. This makes the H-1B1 the preferred visa choice for
                      specialty workers from Chile and Singapore.
                      <br />
                      <br />
                      Another advantage of the H-1B1 visa is that the applicant can
                      directly apply for the visa with a US consulate without the need to
                      obtain prior USCIS approval. Similarly to the H-1B visa, the
                      employer will have to obtain a certified Labor Condition Application
                      from the US Department of Labor as part of the H-1B1 work visa USA
                      process. Immediate relatives, spouses and unmarried children under
                      the age of 21, may accompany the H-1B1 principal to the United
                      States in H-4 dependent status. Our employment-based immigration
                      lawyers have experience with H-1B1 work visa USA sponsorship and can
                      help your organization incorporate the H-1B1 visa in your
                      immigration strategy.
                    </p>
                    <p>
                      <strong>E-3 Australian Specialty Occupation Work Visa USA</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      E-3 work visa USA is reserved for Australian nationals working in a
                      specialty occupation requiring at least a bachelor’s degree. The E-3
                      temporary work visa USA program allows up to 10,500 Australian
                      specialty workers to obtain a US work visa each year. Since the E-3
                      visa is only available to Australian nationals, the annual E-3 visa
                      cap of 10,500 visas has never been reached. Similarly to the H-1B
                      visa process, the E-3 sponsoring company must obtain a certified
                      Labor Condition Application from the US Department of Labor.
                      <br />
                      <br />A major advantage of the E-3 visa is that the applicant does
                      not need to file a separate petition with USCIS and can, instead,
                      directly apply for the E-3 visa with a US consulate or embassy. The
                      E-3 principal beneficiary’s immediate relatives, spouses and
                      unmarried children under the age of 21, may apply for E-3 dependent
                      status to accompany the principal to the United States. Our
                      employment-based immigration team understands how to get a working
                      visa for USA and can help your organization sponsor Australian
                      specialty workers for an E-3 visa.
                    </p>
                    <p>
                      <strong>TN Canadian & Mexican Professional Work Visa USA</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The TN visa is reserved for certain Canadian and Mexican
                      professionals who are seeking temporary employment in the United
                      States. The TN visa was created as a part of the United
                      States-Mexico-Canada Agreement (USMCA), which substituted the North
                      American Free Trade Agreement (NAFTA). To be eligible for a TN work
                      visa USA, you must be (1) a Canadian or Mexican Citizen, (2) a
                      professional worker specializing in one of the enumerated
                      USMCA/NAFTA professions, and (3) have a valid job offer from a US
                      employer to work in your profession. The TN beneficiary’s immediate
                      relatives, spouses and children under the age of 21, may apply for a
                      dependent TD visa to accompany the TN beneficiary to the United
                      States.
                      <br />
                      <br />
                      Canadian TN applicants are visa exempt and apply for admission in TN
                      status at an airport or land border port of entry with US Customs
                      and Border Protection. Mexican TN applicants must apply for a TN
                      visa at US consulate or embassy. Our employment-based immigration
                      lawyers have extensive experience with the TN work visa USA process.
                      Our principal attorney, Adrian Pandev, also understands the personal
                      benefits of the TN visa since he held a TD visa in the past.
                    </p>
                    <p>
                      <strong>R-1 Religious Occupation Work Visa USA</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The R-1 visa is a temporary work visa USA reserved for religious
                      workers in the United States. In addition to members of the clergy,
                      other religious workers, such as missionaries, cantors, and workers
                      in religious healthcare facilities, are eligible for an R-1 visa.
                      Individuals working for a religious organization in a nonreligious
                      function, such as janitors, clerks, volunteers, or maintenance
                      workers do not qualify for an R-1 visa. The R-1 work visa USA is
                      initially issued for up to 30 months and may be extended for a
                      maximum stay of five years. Spouses and unmarried children under 21
                      of an R-1 visa holder may apply for a dependent R-2 visa.
                      <br />
                      <br />
                      Employer’s who would like to start the employment-based green card
                      process for their foreign religious worker could consider the EB-4
                      green card. The EB-4 employment-based green card category has a
                      subcategory for religious workers for which an R-1 visa holder may
                      be eligible. Our attorneys understand how to get a working visa for
                      USA and can guide your organization through the R-1 work visa
                      sponsorship process.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="workVisaUSAHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#workVisaUSACollapseTwo"
                      aria-expanded="true"
                      aria-controls="workVisaUSACollapseTwo">
                      <strong>Intracompany Transfer Work Visa USA</strong>
                    </h2>
                  </div>
                  <div
                    id="workVisaUSACollapseTwo"
                    className="collapse"
                    aria-labelledby="workVisaUSAHeadingTwo"
                    data-parent="#workVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      Certain work visa USA solutions are ideal for companies seeking to
                      transfer employees from their foreign business to their US
                      operations. We regularly represent foreign companies in opening a
                      new subsidiary in the United States and transferring key foreign
                      employees to the US enterprise. Our employment-based immigration
                      attorneys have a thorough understanding of work visa USA sponsorship
                      for intracompany transfers and can develop a custom-tailored
                      strategy for your organization. We always focus on your
                      organization’s goals and strive to develop a comprehensive
                      employment-based immigration strategy that meets your needs. Our
                      attorneys are highly experienced with all intracompany transfer work
                      visas:
                    </p>
                    <p>
                      <strong>L-1A Work Visa USA for Managers or Executives</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      <Link to="/l-1-visa/">The L-1A</Link> work visa is a great solution
                      if you wish to transfer an executive or manager from your foreign
                      company to your US operations. To qualify for an L-1A visa, you will
                      have to demonstrate that the foreign and US companies share
                      ownership. You will also have to show that the employee being
                      transferred was employed in an executive or managerial capacity at
                      the foreign entity for at least one out of the past three years and
                      will be working in an executive or managerial capacity in the United
                      States. The L-1A is a temporary work visa USA which is initially
                      issued for three years and can be extended for a maximum of seven
                      years. An L-1A worker’s immediate family members, spouses and
                      unmarried children under the age of 21, may qualify for an L-2
                      dependent visa which will allow them to accompany the L-1A
                      transferee to the United States. Spouses in L-2 status are eligible
                      for employment authorization in the United States.
                      <br />
                      <br />
                      Although the L-1A visa generally requires a US employer, it is also
                      an effective work visa USA solution for foreign entrepreneurs who
                      have <Link to="/l-1-visa/">opened a new office</Link> in the United
                      States. Moreover, the L-1A is a dual intent visa which makes it a
                      great work visa USA option for employer’s wishing to start the
                      employment-based green card process for a foreign worker. The EB-1C
                      employment-based green card category is specifically designed for
                      multinational executives and has similar requirements to the L-1A
                      visa. Our employment-based immigration lawyers understand how to
                      apply for work visa in USA and have helped numerous foreign
                      executives and managers through the L-1A visa process. We can help
                      devise a custom-tailored work visa USA strategy for your
                      multinational executive or manager case.
                    </p>
                    <p>
                      <strong>
                        L-1B Work Visa USA for Specialized Knowledge Workers
                      </strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The L-1B visa is a great work visa USA solution if you wish to
                      transfer employees with specialized knowledge from your foreign
                      business to your US operations. Specialized knowledge is defined as
                      special knowledge about the sponsoring organization’s products,
                      services, research, equipment, or proprietary processes. Similarly
                      to the L-1A, you must demonstrate that the employee being
                      transferred was employed in a specialized capacity at the foreign
                      entity for at least one out of the past three years and will be
                      working in a specialized capacity in the United States. The L-1B is
                      a temporary work visa USA. It is initially issued for three years
                      and can be extended for a maximum of five years. The L-1B visa
                      holder’s immediate family members, spouses and dependent children
                      under the age of 21, may qualify for an L-2 dependent visa to
                      accompany the L-1B principal beneficiary to the United States. Our
                      employment-based immigration lawyers have significant experience
                      with the L-1B visa process, including cases in which the specialized
                      nature of the position is not immediately apparent and requires
                      additional documentation. The spouse of an L-1B visa holder who is
                      in valid L-2 status is eligible for employment authorization in the
                      United States.
                    </p>
                    <p>
                      <strong>L-1 Visa Blanket Work Visa USA</strong>
                    </p>
                    <p className="text-body mt-4 mb-5">
                      An approved L-1 blanket petition allows organizations to directly
                      apply for an L-1 visa at a US consulate or embassy for their
                      transferees without the need to file a separate L-1 petition with
                      USCIS for each L-1 case. To be eligible for an L-1 blanket petition,
                      your organization (1) must have been doing business in the United
                      States for at least one year and have an office in the United
                      States; (2) must be engaged in commercial trade or services and have
                      three or more foreign or domestic branches, subsidiaries, or
                      affiliates; (3) must have either $25 million in US annual sales, a
                      US workforce of at least 1,000 employees, or had at least ten
                      individual L-1 petitions approved in the past 12 months. The blanket
                      L-1 visa applicant must meet all requirements for an L-1A or L-1B
                      visa, including having been employed with the foreign entity for at
                      least one out of the past three years. Our employment-based
                      immigration team understands the L-1 blanket petition process and
                      the work visa USA sponsorship options. We can advise your
                      organization on whether your organization qualifies for an L-1
                      blanket petition and whether there is a benefit of including this
                      work visa USA option in your organization’s immigration strategy.
                    </p>
                    <p>
                      <strong>E-2 Treaty Investor Work Visa USA</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The <a href="https://www.pandevlaw.com/e-2-visa">E-2 visa</a> great
                      solution for transferring foreign employees to an organization’s US
                      operations. To be eligible for an E-2 visa, a treaty{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/countries-eligible-for-an-e-2-immigration-visa/"
                        target="_blank"
                        rel="noreferrer noopener">
                        country individual or entity
                      </a>{' '}
                      must make a{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/e-2-visa-investment-amount/"
                        target="_blank"
                        rel="noreferrer noopener">
                        substantial investment
                      </a>{' '}
                      in a US enterprise. This US enterprise may thereafter sponsor a
                      temporary E-2 work visa USA for foreign employees who are citizens
                      of the same treaty country. The E-2 employees must work in either an
                      executive or supervisory role or have specialized skills that make
                      them essential to the US enterprise. The E-2 visa is a great
                      employment-based immigration solution for both small and large
                      organizations alike because it does not{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/requirements-for-e-2-investor-visa/"
                        target="_blank"
                        rel="noreferrer noopener">
                        require
                      </a>{' '}
                      a minimum{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/e-2-investor-visa-how-much-do-i-have-to-invest/"
                        target="_blank"
                        rel="noreferrer noopener">
                        investment ammount
                      </a>
                      . E-2 visas are issued for a validity period of up to five years
                      depending on the applicant’s country of nationality. The E-2 work
                      visa USA may be extended indefinitely, which is an advantage of the
                      E-2 visa over the L-1. Immediate relatives, spouses and children
                      under the age of 21, of E-2 visa applicants may apply for an E-2
                      dependent visa. A spouse in E-2 dependent status is eligible for
                      employment authorization in the United States. The EB-1C
                      employment-based green card is often a viable option for executives
                      or managers in E-2 status that wish to start the employment-based
                      green card process. Our employment-based immigration lawyers
                      understand how to get a working visa for USA and can help your
                      organization sponsor qualifying employees for an E-2 visa.
                    </p>
                    <p>
                      <strong>E-1 Treaty Trader Work Visa USA</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The E-1 visa is reserved for treaty country enterprises that engage
                      in substantial trade with the treaty country. If a US enterprise is
                      owned by an individual or entity that is a citizen of a treaty
                      country and the US enterprise engages in substantial trade with the
                      treaty country, then the US enterprise may sponsor citizens of the
                      treaty country for an E-1 visa. The E-1 worker must be employed in
                      an executive or supervisory position or possess skills that are
                      essential to the operation of the US enterprise. The E-1 is a
                      temporary work visa USA which may be issued for up to five years
                      depending on the applicant’s nationality. The E-1 visa may be
                      extended indefinitely. The spouse and unmarried children under the
                      age of 21 of an E-1 visa holder may apply for an E-1 dependent visa.
                      A spouse in E-1 dependent status is eligible for work authorization
                      in the United States.
                      <br />
                      <br />
                      Organizations are generally permitted to start the employment-based
                      green card process for their E-1 employees. If you are in E-1 status
                      and work in an executive or managerial capacity, the EB-1C
                      employment-based green card may be an attractive employment-based
                      green card solution for your case. Our attorneys have extensive
                      experience with the work visa USA sponsorship process and can help
                      your organization apply for an E-1 visa.
                    </p>
                    <p>
                      <strong>B-1 Business Visitor Work Visa USA</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The B-1 business visitor visa is a good work visa USA opportunity
                      for people who want to enter the United States temporarily for
                      business purposes. The B-1 visa is a nonimmigrant visa intended to
                      be used for short business trips to the United States. To be
                      eligible for a B-1 visa, you must demonstrate that (1) your proposed
                      business activities in the United States are permitted in B-1
                      status, (2) you are entering the United States for a temporary
                      period, and (3) you have significant ties to your home country that
                      will ensure your departure from the United States at the end of your
                      business trip. The following activities are permitted in B-1 status:
                      consulting with business associates, negotiating a contract,
                      participating in short-term training, or attending scientific,
                      educational, professional or business conferences or conventions.
                      Although the B-1 visa is often overlooked, it is a useful work visa
                      USA solution for organizations wishing to bring foreign employees to
                      the United States for a short-term, limited assignment. Our
                      employment-based immigration lawyers understand the benefits of the
                      B-1 visa and have the necessary experience to guide you through your
                      B-1 temporary work visa USA application.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="workVisaUSAHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#workVisaUSACollapseThree"
                      aria-expanded="true"
                      aria-controls="workVisaUSACollapseThree">
                      <strong>Athlete & Entertainer Work Visa USA</strong>
                    </h2>
                  </div>
                  <div
                    id="workVisaUSACollapseThree"
                    className="collapse"
                    aria-labelledby="workVisaUSAHeadingThree"
                    data-parent="#workVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      The P-1 visa is available to certain athletes and entertainers and
                      their support staff. If you are an athlete or entertainer entering
                      the United States to perform at one or more events, then the P-1
                      visa may be a good temporary work visa USA solution for you.
                    </p>
                    <p>
                      <strong>P-1A Athlete Work Visa USA</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The P-1A temporary work visa USA category is reserved for foreign
                      nationals entering the United States to participate in an athletic
                      competition, either individually or as a group, at an
                      internationally recognized level of performance. The P-1A is a
                      temporary work visa that is issued for a maximum initial duration of
                      five years. The P-1A visa may be extended for an additional
                      five-year period for a maximum of ten years. Essential support
                      personnel of a P-1A athlete, such as coaches, scouts, trainers, and
                      other team officials, may apply for a P-1S visa to accompany the
                      athlete or group to the United States. The spouse and unmarried
                      children under the age of 21 of a P-1A visa holder may apply for a
                      P-4 dependent visa. Our attorneys understand how to apply for work
                      visa in USA and guide you through the P-1A visa application process.
                    </p>
                    <p>
                      <strong>P-1B Entertainment Group Work Visa USA</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The P-1B work visa USA category is reserved for entertainment groups
                      that have been established for a minimum of one year and are
                      recognized internationally as outstanding in their discipline for a
                      sustained and substantial period of time. You are ineligible for a
                      P-1B visa if you are a solo artist because the P-1B visa is only
                      available to entertainment groups. The entertainment group can vary
                      in size from as little as two members to several hundred, such as in
                      the case of large symphony orchestras. To qualify for a P-1B visa,
                      you must demonstrate that at least 75 percent of all group members
                      have been a part of the group for at least one year. It is not
                      required that the entertainment group be foreign based. A US
                      entertainment group that meets the 75 percent membership requirement
                      may use the P-1B visa to sponsor a new foreign national group
                      member.
                      <br />
                      <br />
                      Essential support personnel who are integral to the group’s
                      performance may be eligible for a P-1S visa to accompany the group
                      to the United States. Immediate relatives, such as spouses and
                      unmarried children under the age of 21, of P-1B visa holders are
                      eligible for a P-4 dependent visa. If you are a member of an
                      entertainment group and wondering how to get a working visa for usa,
                      our employment-based immigration lawyers can advise you on the right
                      immigration strategy for your case.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="workVisaUSAHeadingFour">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#workVisaUSACollapseFour"
                      aria-expanded="true"
                      aria-controls="workVisaUSACollapseFour">
                      <strong>Extraordinary Ability Work Visa USA</strong>
                    </h2>
                  </div>
                  <div
                    id="workVisaUSACollapseFour"
                    className="collapse"
                    aria-labelledby="workVisaUSAHeadingFour"
                    data-parent="#workVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      Individuals who possess extraordinary ability in their field may be
                      eligible for an O-1 work visa USA. The O-1 visa allows foreign
                      nationals who are at the top of their field in the arts, sciences,
                      sports, education, business, film, or television industries to enter
                      the United States to work in their field for an initial period of
                      three years. Due to the similar requirements between the O-1 visa
                      and the EB-1A employment-based green card, the O-1 is a good work
                      visa USA option before starting the employment-based green card
                      process. Our employment-based immigration team is experienced with
                      all O visa categories:
                    </p>
                    <p>
                      <strong>
                        O-1A Work Visa for Individuals in Business, Education, Science or
                        Athletics
                      </strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      Individuals who possess extraordinary ability in business,
                      education, science, or athletics are eligible for an O-1A work visa
                      USA. To qualify for an O-1A visa, you must demonstrate that you have
                      won a prestigious award, such as a Nobel Prize. Alternatively, you
                      may qualify for an O-1A visa if you can demonstrate extraordinary
                      ability through other evidence, such as the receipt of lesser awards
                      in your field, membership in associations requiring outstanding
                      achievement, published materials about you in major media
                      publications, serving in a critical role for organizations with a
                      distinguished reputation, or commanding a high salary compared to
                      others in your field. Immediate family members of an O-1A visa
                      holder may apply for an O-3 dependent visa. Dual intent is
                      essentially allowed for O-1A visas. If carefully planned, O-1A visa
                      holders may apply for an employment-based green card. Our
                      employment-based immigration team has the necessary know-how to
                      guide you through your O-1A work visa USA application.
                    </p>
                    <p>
                      <strong>
                        O-1B Work Visa for Individuals in the Arts, Motion Pictures or
                        Television
                      </strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The O-1B visa is reserved for individuals with extraordinary ability
                      in the arts, motion pictures, or television. You may show
                      eligibility for an O-1B visa through a one-time major achievement,
                      such as an Academy Award or Emmy Award. Alternatively, you can
                      qualify for an O-1B visa if you can prove your extraordinary ability
                      through other evidence, such as a lead performance in a
                      distinguished production, major commercial or critically acclaimed
                      successes, receiving critical reviews or media publications about
                      your work, or commanding a high salary. Your spouse and dependent
                      children under the age of 21 may apply for an O-3 dependent visa to
                      accompany you to the United States in O-1B status. Beneficiaries of
                      an O-1B work visa USA may be eligible for an EB-1A employment-based
                      green card. Our employment-based immigration lawyers understand work
                      visa USA sponsorship and can help develop the right O-1B strategy
                      for your case.
                    </p>
                    <p>
                      <strong>O-2 Work Visa for Support Personnel of O-1</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      An O-2 work visa USA is issued to support personnel accompanying the
                      O-1 principal beneficiary to the United States. The O-2 applicant’s
                      work must be an integral component of the O-1 visa holder’s
                      activities in the United States. To qualify for an O-2 visa, you
                      must possess important skills and expertise that are critical to the
                      O-1's effective performance and which cannot be readily done by a US
                      worker. An O-2 visa holder’s spouse and unmarried children under 21
                      may apply for an O-3 dependent visa. Our attorneys know how to get a
                      working visa for USA and can help guide you through the O-2 visa
                      application process.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="workVisaUSAHeadingFive">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#workVisaUSACollapseFive"
                      aria-expanded="true"
                      aria-controls="workVisaUSACollapseFive">
                      <strong>Temporary & Seasonal Work Visa USA</strong>
                    </h2>
                  </div>
                  <div
                    id="workVisaUSACollapseFive"
                    className="collapse"
                    aria-labelledby="workVisaUSAHeadingFive"
                    data-parent="#workVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      The H-2 visa is a special work visa USA category reserved for US
                      employers who are seeking temporary and seasonal foreign workers
                      because they cannot find qualified US applicants. Both skilled and
                      unskilled workers may apply for an H-2 visa. The H-2 visa category
                      is broken down into two sub categories: H-2A visa for agricultural
                      workers and H-2B visa for non-agricultural workers. Our
                      employment-based immigration attorneys are experienced with work
                      visa USA sponsorship and can help your organization sponsor
                      employees for both types of H-2 visas:
                    </p>
                    <p>
                      <strong>
                        H-2A Work Visa USA for Temporary & Seasonal Agricultural Workers
                      </strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The H-2A temporary work visa USA allows employers to sponsor foreign
                      workers for temporary or seasonal agricultural work in the United
                      States. To successfully petition for an H-2A visa, the employer must
                      show that it could not find qualified US workers for the position.
                      The employer has to also demonstrate the temporary nature of the
                      position and that hiring foreign workers will not adversely affect
                      the wages or working conditions of similarly-employed US workers.
                      After approval of the H-2A petition, each individual H-2A worker
                      must apply for an H-2A visa at a US consulate or embassy. The spouse
                      and unmarried children under 21 of an H-2A visa holder may apply for
                      an H-4 dependent visa. Our employment-based immigration lawyers can
                      guide your organization through the H-2A agricultural work visa USA
                      process.
                    </p>
                    <p>
                      <strong>
                        H-2B Work Visa USA for Temporary & Seasonal Non-Agricultural
                        Workers
                      </strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The H-2B temporary work visa USA is used by employers to sponsor
                      foreign employees for temporary or seasonal non-agricultural
                      positions in the United States. To become approved to hire H-2B
                      workers, an employer must demonstrate to the government that the
                      position is temporary or seasonal and that the employer could not
                      find qualified US workers for the position. Additionally, the
                      employer must ensure that hiring foreign employees in H-2B status
                      will not adversely affect similarly-employed US workers’ salaries or
                      working conditions. Once the employer’s H-2B petition is approved by
                      USCIS, each prospective H-2B employee must individually apply for an
                      H-2B visa at a US consulate or embassy. Immediate relatives, spouses
                      and dependent children under the age of 21, of H-2B visa holders may
                      apply for an H-4 dependent visa. Our attorneys understand work visa
                      USA sponsorship and can develop an effective H-2B work visa USA
                      strategy for your organization.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="workVisaUSAHeadingSix">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#workVisaUSACollapseSix"
                      aria-expanded="true"
                      aria-controls="workVisaUSACollapseSix">
                      <strong>Dependents of Work Visa USA Holders</strong>
                    </h2>
                  </div>
                  <div
                    id="workVisaUSACollapseSix"
                    className="collapse"
                    aria-labelledby="workVisaUSAHeadingSix"
                    data-parent="#workVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      Immediate family members may generally accompany the principal work
                      visa USA beneficiary to the United States. Spouses and dependent
                      children under the age of 21 are eligible to apply for a dependent
                      visa and may be included as derivatives in employment-based green
                      card applications. Our employment-based immigration team has
                      successfully represented numerous applicants through the dependent
                      visa application process, including cases with admissibility issues
                      for prior visa or criminal violations. We represent individuals in
                      all work visa USA dependent categories:
                    </p>
                    <p>
                      <strong>H-4 Dependent Visa</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The H-4 visa is reserved for immediate relatives of H-1, H-2, and
                      H-3 visa holders. Only spouses and unmarried children under 21 of
                      H-1, H-2, and H-3 visa holders are eligible for an H-4 visa. The H-4
                      visa is generally issued for the same period as the principal
                      beneficiary’s H work visa. Individuals in H-4 status are generally
                      not eligible for employment authorization. Spouses of H-1B workers
                      are eligible for employment authorization if the H-1B spouse has an
                      approved I-140 immigrant petition or has received a one-year
                      extension of H-1B status beyond the final sixth year based on a
                      filed{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/how-to-get-an-employer-sponsored-green-card/"
                        target="_blank"
                        rel="noreferrer noopener">
                        PERM labor certification
                      </a>{' '}
                      . Children in H-4 status are not eligible for employment
                      authorization. Our principal attorney, Adrian Pandev, held an H-4
                      visa for several years. As such, in addition to our employment-based
                      immigration team’s extensive experience with the H-4 temporary work
                      visa USA, we also understand the personal benefits of the H-4 visa.
                    </p>
                    <p>
                      <strong>E Dependent Visa</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      Spouses and unmarried children under 21 of E-1, E-2, and E-3 visa
                      holders are eligible for an E dependent visa.These immediate family
                      members may apply for an E-1, E-2, or E-3 dependent visa
                      concurrently with the principal E visa beneficiary. Spouses in
                      dependent E-1, E-2, or E-3 status are eligible for employment
                      authorization. Our employment-based immigration lawyers understand
                      how to get a working visa in USA and have guided numerous families
                      through the E-2 dependent visa application and associated employment
                      authorization application process.
                    </p>
                    <p>
                      <strong>L-2 Dependent Visa</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      Immediate family members of L-1 visa holders, such as spouses and
                      unmarried children under 21, may apply for an L-2 visa. An L-2
                      dependent visa is generally granted for the same duration as the L-1
                      principal beneficiary’s visa. Spouses in L-2 status are eligible for
                      employment authorization. Our employment-based immigration team
                      understands work visa USA sponsorship and has represented many
                      families through the L-2 application and L-2 employment
                      authorization processes.
                    </p>
                    <p>
                      <strong>O-3 Dependent Visa</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The O-3 dependent visa is reserved for the spouse and unmarried
                      children under 21 of O-1 and O-2 visa holders who wish to accompany
                      the primary O visa holder to the United States. An individual
                      holding O-3 status is generally permitted to remain in the United
                      States as long as the principal O beneficiary maintains valid O
                      status. O-3 visa dependents are allowed to engage in full time or
                      part-time study in the United States but are not eligible for
                      employment authorization. Our attorneys understand how to apply for
                      work visa in USA and can help you and your family obtain an O-3
                      dependent visa.
                    </p>
                    <p>
                      <strong>P-4 Dependent Visa</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      The P-4 dependent visa is available to unmarried children under the
                      age of 21 and spouses of P-1, P-2, and P-3 visa holders. Once a
                      child reaches the age of 21 or becomes married, she is no longer
                      eligible for a P-4 dependent visa. Individuals in P-4 status are not
                      eligible for employment authorization but may study in the United
                      States. Our attorneys regularly represent clients in dependent visa
                      applications and can advise you on the right immigration strategy
                      for your entire family.
                    </p>
                    <p>
                      <strong>R-2 Dependent Visa</strong>
                    </p>{' '}
                    <p className="text-body mt-4 mb-5">
                      Immediate family members, spouses and unmarried children under 21,
                      of R-1 visa holders may apply for an R-2 dependent visa. Spouses and
                      children in R-2 status are not eligible for employment authorization
                      in the United States. They may, however, engage in full time or
                      part-time study in the United States. Our attorneys understand work
                      visa USA sponsorship and can devise the best R-2 dependent visa
                      strategy for your family.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="workVisaUSAHeadingSeven">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#workVisaUSACollapseSeven"
                      aria-expanded="true"
                      aria-controls="workVisaUSACollapseSeven">
                      <strong>Consular Processing & Change/Adjustment of Status</strong>
                    </h2>
                  </div>
                  <div
                    id="workVisaUSACollapseSeven"
                    className="collapse"
                    aria-labelledby="workVisaUSAHeadingSeven"
                    data-parent="#workVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      Our employment-based immigration attorneys have extensive experience
                      helping clients obtain a work visa USA at a US consulate or embassy
                      outside the United States and in filing for a nonimmigrant status or
                      an employment-based green card inside the United States. Generally,
                      the consular process is used by individual applicants who are
                      outside the United States, while the change of status process is
                      reserved for applicants who are physically present inside the United
                      States. There are several advantages to both processes. The best
                      strategy on whether to pursue the consular process or a change of
                      status petition will depend on the particular circumstances of your
                      case. Our attorneys have successfully represented clients in work
                      visa applications at US consulates in all corners of the world. We
                      have also helped numerous applicants through the change of status
                      and adjustment of status processes. We have a thorough understanding
                      of both processes and can devise the best strategy for your case
                      regardless of whether you are applying for a work visa USA or an
                      employment-based green card.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/building-with-flag.jpg"
                alt="Worksite Compliance & Corporate Immigration Policy"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                Worksite Compliance & Corporate Immigration Policy
              </h2>
              <p className="text-body mb-5">
                Our experienced employment-based immigration lawyers advise organizations
                in the development of immigration policies that ensure compliance with all
                immigration laws and regulations. Our work visa USA compliance services
                include:
              </p>
              <div className="accordion" id="immigrationCompliance">
                <div className="mb-5">
                  <div id="immigrationComplianceHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#immigrationComplianceCollapseOne"
                      aria-expanded="true"
                      aria-controls="immigrationComplianceCollapseOne">
                      <strong>LCA Compliance</strong>
                    </h2>
                  </div>
                  <div
                    id="immigrationComplianceCollapseOne"
                    className="collapse show"
                    aria-labelledby="immigrationComplianceHeadingOne"
                    data-parent="#immigrationCompliance">
                    <p className="text-body mt-4 mb-5">
                      During the H-1B, H-1B1, and E-3 work visa USA petition process, an
                      employer is required to submit a Labor Condition Application (LCA)
                      to the US Department of Labor. In the LCA, the employer attests that
                      it has complied with all requirements for hiring a foreign specialty
                      occupation worker, including that it has provided notice of the LCA
                      filing and will pay the foreign worker a wage not lower than the
                      prevailing wage in the geographic area of employment or that paid to
                      workers holding similar positions at the organization. Only after
                      the US Department of Labor certifies the LCA may an H-1B, H-1B1, or
                      E-3 work visa USA be granted. If an employer is later found to have
                      violated the attestations made in the LCA, the employer may be
                      sanctioned, fined, or barred from sponsoring foreign workers for a
                      work visa in the future.
                      <br />
                      <br />
                      Our employment-based immigration lawyers work with organizations to
                      develop effective LCA compliance policies and thereby limit
                      liability. As part of our LCA compliance services, we conduct
                      internal audits of LCA files and devise company policies that ensure
                      ongoing compliance. We have experience training human resource
                      personnel at proper LCA public access file retention, ensuring that
                      all documents are properly maintained in accordance with
                      regulations. Our attorneys have the necessary knowledge of the LCA
                      laws and regulations to represent your organization through an LCA
                      audit or investigation by the government.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="immigrationComplianceHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#immigrationComplianceCollapseTwo"
                      aria-expanded="false"
                      aria-controls="immigrationComplianceCollapseTwo">
                      <strong>I-9 Compliance</strong>
                    </h2>
                  </div>
                  <div
                    id="immigrationComplianceCollapseTwo"
                    className="collapse"
                    aria-labelledby="immigrationComplianceHeadingTwo"
                    data-parent="#immigrationCompliance">
                    <div className="mt-4">
                      Our employment-based immigration attorneys help employers develop
                      and implement Form I-9, Employment Eligibility Verification
                      processes that comply with all relevant laws and regulations.
                      Organizations must receive a completed Form I-9 from all new
                      employees and must review the employee’s employment authorization
                      and identity documents within the first three business days of the
                      start of their employment. With the increase of U.S. Immigration and
                      Customs Enforcement (ICE) I-9 audits nationwide, it is important
                      that US employers ensure that all of their I-9 forms are properly
                      completed and retained. Employers who are found to have violated I-9
                      rules may face sanctions.
                      <br />
                      <br />
                      Our employment-based immigration attorneys develop successful
                      organization wide I-9 compliance procedures. We conduct internal
                      audits of I-9 records and review all relevant documents to evaluate
                      compliance. We train and work with human resources personnel to
                      correct any defects and ensure proper I-9 form retention. Our
                      employment-based immigration attorneys have the necessary
                      understanding of the laws and regulations to represent your
                      organization through a government I-9 investigation.
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="immigrationComplianceHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#immigrationComplianceCollapseThree"
                      aria-expanded="false"
                      aria-controls="immigrationComplianceCollapseThree">
                      <strong>E-Verify Compliance</strong>
                    </h2>
                  </div>
                  <div
                    id="immigrationComplianceCollapseThree"
                    className="collapse"
                    aria-labelledby="immigrationComplianceHeadingThree"
                    data-parent="#immigrationCompliance">
                    <div className="mt-4">
                      E-Verify is an online system operated by USCIS which employers can
                      use to confirm employment eligibility of new hires. The program is
                      free and generally voluntary. Employers in certain states, federal
                      contractors, and employer’s seeking to hire foreign nationals in F-1
                      STEM OPT status are mandated to participate in the E-Verify program.
                      Our employment-based immigration attorneys provide strategic counsel
                      and help organizations develop proper E-Verify procedures for all
                      their new hires. We also represent employers through government
                      investigations and audits.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="immigrationComplianceHeadingFour">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#immigrationComplianceCollapseFour"
                      aria-expanded="false"
                      aria-controls="immigrationComplianceCollapseFour">
                      <strong>Corporate Immigration Policy Planning</strong>
                    </h2>
                  </div>
                  <div
                    id="immigrationComplianceCollapseFour"
                    className="collapse"
                    aria-labelledby="immigrationComplianceHeadingFour"
                    data-parent="#immigrationCompliance">
                    <div className="mt-4">
                      Our attorneys use their extensive knowledge of the work visa USA and
                      employment-based green card processes to develop effective
                      organization-wide immigration policies. An effective corporate
                      immigration policy ensures legal compliance, facilities hiring
                      strategies, and provides a positive experience to foreign national
                      employees. Our attorneys work closely with human resources
                      departments, providing training and developing essential resources,
                      such as handbooks and immigration policy manuals. Our
                      employment-based immigration attorneys help organizations meet
                      hiring goals through the development of robust immigration policies
                      that are compliant with federal laws and regulations.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      {get(category, 'posts.nodes.length', 0) > 0 && (
        <>
          <hr className="my-8" />
          <CategorySlider index={2} category={category} />
        </>
      )}
      <AbTestingDeck
        title="Employment-Based Immigration Testimonials"
        testimonials={EMPLOYMENT_BASED_IMMIGRATION_TESTIMONIALS}
      />
      <CtaDeck />
      <PilarNavigation />
      <SuccessStoriesDeck />
    </Layout>
  );
}

export default EmploymentBaseImmigration;

export const query = graphql`
  query EmploymentBaseImmigrationQuery {
    category: wpCategory(slug: { eq: "employment-based-immigration" }) {
      ...BlogLandingHeroPost
    }
  }
`;
