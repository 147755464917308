export const EMPLOYMENT_BASED_IMMIGRATION_TESTIMONIALS = [
	{
		text: "Mr. Adrian M Pandev impressed me with utmost professionalism, knowledge, experience and commitment. He was so quick and the entire process went on so smoothly and in a timely manner. My H1b extension was approved so quickly. Out of five immigration lawyers that I have come across in my previous petitions filings, I rank Mr. Pandev as the first and the best immigration lawyer that I have come across. Thank you very much Mr. Pandev for your courtesy, kindness, professionalism, communication and commitment. Your services are greatly appreciated and we wouldn't hesitate to hire you again in the future.  Thank you again.",
		name: 'Prasad Venki',
		stars: 5,
	},
	{
		text: 'I could not have asked for a better firm to help with my H1B immigration case! The team was highly strategic in helping me evaluate issues around (i) visa selection, (ii) timing, and (iii) tertiary issues (taxes, etc). The firm was very responsive to questions and proceeded quickly/efficiently with my case.\r',
		name: 'Taylor S',
		stars: 5,
	},
	{
		text: 'Adrian Pandev is an excellent immigration lawyer who works diligently to ensure that his clients’ needs are taking care of in a timely manner. Not only that, Mr Pandev is a great communicator who helped my family and me with my employment Green Card. With his expert knowledge and attentiveness I was able to feel hopeful about the best outcome for each step. Mr Pandev is a great lawyer and because of this, we were APPROVED with our Permanent Resident card.',
		name: 'Camille Davis',
		stars: 5,
	},
	{
		text: 'Adrian successfully obtained our H-1B and H-4 extensions in less than 30 days. He worked diligently, efficiently and was always available to answer any questions. Adrian is a great lawyer, he is highly recommended.',
		name: 'Simone Hinds',
		stars: 5,
	},
	{
		text: 'We were very much  impressed the way he study the case and approach the clients.  With his brilliant idea instead of getting my husband’s EAD within 90 days, we have been approved in two weeks. He is a very professional and highly knowledgeable person. I highly recommend him.',
		name: 'Sudharani Alamanda',
		stars: 5,
	},
	{
		text: 'Mr Pandev is a very professional lawyer who makes time for his clients. He is super efficient and he helped my family and I go through a very smooth transition from J1 visa to H1B visa. I have no hesitation in recommending his services as I know you will be fully satisfied! Thanks Mr Pandev you are officially my immigration lawyer!!',
		name: 'Sophia Thomas',
		stars: 5,
	},
	{
		text: "Our company enjoyed a great deal of professionalism by Mr Pandev and his team in our corporate non-immigrant visa application process. I recommend Pandev Law's office as they are knowledgeable, reliable and punctual!",
		name: 'Smart Management Group',
		stars: 5,
	},
	{
		text: "9/10 of the lawyers I consulted with said that L1-B is highly scrutinized and there's a little chance to win the case. When Adrian and I met, he was positive that we could win the case with the proper strategy. This was one of the things I definitely needed—a support and a positive outlook aside from legal, objective thinking. Upon starting the case, it took several months to compile all the documents required. This was a bit of a delay on my employer. But Michael was extremely responsive and quick. He'd always send me a confirmation email whenever I share documents, which provides me a peace of mind that the lawyers are not ignoring me. Michael was also quick on writing letters and organizing the documents. Precision was all along there too, considering L1 visas could be tricky sometimes. The only thing I'd say is that collaboration and communication should be established between the lawyers and clients. Michael and I worked together in putting together a rigorous documentation of the relationship between the U.S. and local businesses and my specialized skills. Overall, the process and documentation was long but it was worth it. My petition has been approved as well as my visa.",
		name: 'Keziah Cruz',
		stars: 5,
	},
	{
		text: 'The  lawyers at Pandev Law are absolutely awesome. They worked meticulously to help me and my family as we adjusted our status from the J1 to the H1 visa. My case was handled professionally but most of all they cared.  They have a remarkable system of communicating with me about my case and the  response time was always prompt. I was never left in the dark  because I was clearly told what the next steps would be. I cannot say enough about this team but thank you for having me wake up to the positive emails. Highly recommended law firm.',
		name: 'Marlene Golding',
		stars: 5,
	},
];
